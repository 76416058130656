<template>
  <!--begin::Advance Table Widget 10-->
  <div>
    <b-tabs active-nav-item-class="font-weight-bold">
      <b-tab title="Belum lulus" lazy>
        <DataList
          :title="'Senarai Permohonan: Batch ' + this.$route.params.batch_no"
          :appsData="pendingApplications"
          :appsConfig="headers"
          :detailButton="true"
          :deleteButton="true"
          @onDetail="goToEditPage($event)"
          @onDelete="goToDeleteApp($event)"
        ></DataList>

        <div class="card card-custom gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder"
                >Senarai Permohonan: Batch {{ this.$route.params.batch_no }}
              </span>
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-0">
            <!--begin::Table-->
            <div class="table-responsive table-hover">
              <table class="table table-head-custom table-vertical-center">
                <thead>
                  <tr class="text-center">
                    <th class="pl-0">No.</th>
                    <th class="pl-0">Cawangan</th>
                    <th class="pl-0">No. Rujukan</th>
                    <th>Tarikh Permohonan</th>
                    <th class="pl-0">Jenis Permohonan</th>
                    <th>Status</th>
                    <th>Nota</th>
                    <th>Kelulusan 1</th>
                    <th>Kelulusan 2</th>
                    <th class="pr-0 text-right" style="min-width: 100px">Tindakan</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="pendingApplications.data.length > 0">
                    <tr
                      class="text-center"
                      v-for="(application, i) in pendingApplications.data"
                      :key="application.id"
                    >
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          i + pendingApplications.from
                        }}</span>
                      </td>
                      <td>
                        <span
                          class="
                            label label-md label-inline
                            font-weight-bolder
                            h-25
                            label-light-primary
                          "
                        >
                          {{ application.branch.name.toUpperCase() }}
                        </span>
                      </td>
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          application.ref_no
                        }}</span>
                      </td>
                      <td>
                        <span class="font-weight-bolder d-block font-size-md">{{
                          application.submitted_date | formatDateTime
                        }}</span>
                      </td>
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          application.application_type.name
                        }}</span>
                        <span class="text-muted font-weight-bold">{{
                          application.subtitle
                        }}</span>
                      </td>
                      <td>
                        <span
                          class="
                            label label-md label-inline
                            font-weight-bold
                            h-25
                          "
                          v-bind:class="`label-light-${application.status.color_class}`"
                          >{{ application.status.nameMS | upperCase }}</span
                        >
                      </td>
                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="font-weight-bolder d-block font-size-md"
                              v-bind="attrs"
                              v-on="on"
                              style="
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                max-width: 400px;
                              "
                            >
                              {{ application.remark }}
                            </div>
                          </template>
                          <span>{{ application.remark }}</span>
                        </v-tooltip>
                      </td>
                      <td>
                        {{
                          application.approved_application.length > 0
                            ? application.approved_application[0].user.full_name
                            : ""
                        }}
                      </td>
                      <td>
                        {{
                          application.approved_application.length > 1
                            ? application.approved_application[1].user.full_name
                            : ""
                        }}
                      </td>

                      <td class="pr-0 text-right">
                        <v-btn
                          icon
                          class="
                            btn btn-icon btn-light btn-hover-primary btn-sm
                            mx-3
                          "
                          @click="next(application)"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                            <inline-svg
                              src="/media/svg/icons/Communication/Write.svg"
                            ></inline-svg>
                            <!--end::Svg Icon-->
                          </span>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <td colspan="10" class="text-center">
                      <p>Tiada rekod</p>
                    </td>
                  </template>
                </tbody>
              </table>
            </div>

            <nav>
              <ul class="pagination justify-content-end">
                <div v-for="link in pendingApplications.links" :key="link.label">
                  <li
                    v-bind:class="`page-item ${
                      link.url == null ? 'disabled' : ''
                    } ${link.active == true ? 'active' : ''}`"
                  >
                    <a
                      class="page-link"
                      @click="paginate(link.url)"
                      :aria-label="link.label"
                      >{{ decodeHtml(link.label) }}</a
                    >
                  </li>
                </div>
              </ul>
            </nav>
          </div>
        </div>
      </b-tab>
      <b-tab title="Lulus" lazy>
        <div class="card card-custom gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder"
                >Senarai Lulus: Batch {{ this.$route.params.batch_no }}
              </span>
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-0">
            <!--begin::Table-->
            <div class="table-responsive table-hover">
              <table class="table table-head-custom table-vertical-center">
                <thead>
                  <tr class="text-center">
                    <th class="pl-0">No.</th>
                    <th class="pl-0">Cawangan</th>
                    <th class="pl-0">No. Rujukan</th>
                    <th>
                      Tarikh Permohonan
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Down-2.svg-->
                    </th>
                    <th class="pl-0">Jenis Permohonan</th>
                    <th>Status</th>
                    <th>Nota</th>
                    <th>Kelulusan 1</th>
                    <th>Kelulusan 2</th>
                    <th class="pr-0 text-right" style="min-width: 100px">
                      Tindakan
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="apprApplications.data.length > 0">
                    <tr
                      class="text-center"
                      v-for="(application, i) in apprApplications.data"
                      :key="application.id"
                    >
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          i + apprApplications.from
                        }}</span>
                      </td>
                      <td>
                        <span
                          class="
                            label label-md label-inline
                            font-weight-bolder
                            h-25
                            label-light-primary
                          "
                        >
                          {{ application.branch.name.toUpperCase() }}
                        </span>
                      </td>
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          application.ref_no
                        }}</span>
                      </td>
                      <td>
                        <span class="font-weight-bolder d-block font-size-md">{{
                          application.submitted_date | formatDateTime
                        }}</span>
                      </td>
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          application.application_type.name
                        }}</span>
                        <span class="text-muted font-weight-bold">{{
                          application.subtitle
                        }}</span>
                      </td>
                      <td>
                        <span
                          class="
                            label label-md label-inline
                            font-weight-bold
                            h-25
                          "
                          v-bind:class="`label-light-${application.status.color_class}`"
                          >{{ application.status.nameMS | upperCase }}</span
                        >
                      </td>
                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="font-weight-bolder d-block font-size-md"
                              v-bind="attrs"
                              v-on="on"
                              style="
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                max-width: 400px;
                              "
                            >
                              {{ application.remark }}
                            </div>
                          </template>
                          <span>{{ application.remark }}</span>
                        </v-tooltip>
                      </td>
                      <td>
                        <span class="font-weight-bolder d-block font-size-md">
                          {{
                            application.approved_application.length > 0
                              ? application.approved_application[0].user.full_name
                              : ""
                          }}
                        </span>
                      </td>
                      <td>
                        <span class="font-weight-bolder d-block font-size-md">
                          {{
                            application.approved_application.length > 1
                              ? application.approved_application[1].user.full_name
                              : ""
                          }}
                        </span>
                      </td>

                      <td class="pr-0 text-right">
                        <v-btn
                          icon
                          class="
                            btn btn-icon btn-light btn-hover-primary btn-sm
                            mx-3
                          "
                          @click="next(application)"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                            <inline-svg
                              src="/media/svg/icons/Communication/Write.svg"
                            ></inline-svg>
                            <!--end::Svg Icon-->
                          </span>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <td colspan="10" class="text-center">
                      <p>Tiada rekod</p>
                    </td>
                  </template>
                </tbody>
              </table>
            </div>

            <nav>
              <ul class="pagination justify-content-end">
                <div v-for="link in pendingApplications.links" :key="link.label">
                  <li
                    v-bind:class="`page-item ${
                      link.url == null ? 'disabled' : ''
                    } ${link.active == true ? 'active' : ''}`"
                  >
                    <a
                      class="page-link"
                      @click="paginate(link.url)"
                      :aria-label="link.label"
                      >{{ decodeHtml(link.label) }}</a
                    >
                  </li>
                </div>
              </ul>
            </nav>
          </div>
        </div>
      </b-tab>
      <b-tab title="KIV">
        <div class="card card-custom gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder"
                >Senarai KIV: Batch {{ this.$route.params.batch_no }}
              </span>
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-0">
            <!--begin::Table-->
            <div class="table-responsive table-hover">
              <table class="table table-head-custom table-vertical-center">
                <thead>
                  <tr class="text-center">
                    <th class="pl-0">No.</th>
                    <th class="pl-0">Cawangan</th>
                    <th class="pl-0">No. Rujukan</th>
                    <th>
                      Tarikh Permohonan
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Down-2.svg-->
                    </th>
                    <th class="pl-0">Jenis Permohonan</th>
                    <th>Status</th>
                    <th>Nota</th>
                    <th>Kelulusan 1</th>
                    <th>Kelulusan 2</th>
                    <th class="pr-0 text-right" style="min-width: 100px">
                      Tindakan
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="KIVApplications.data.length > 0">
                    <tr
                      class="text-center"
                      v-for="(application, i) in KIVApplications.data"
                      :key="application.id"
                    >
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          i + KIVApplications.from
                        }}</span>
                      </td>
                      <td>
                        <span
                          class="
                            label label-md label-inline
                            font-weight-bolder
                            h-25
                            label-light-primary
                          "
                        >
                          {{ application.branch.name.toUpperCase() }}
                        </span>
                      </td>
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          application.ref_no
                        }}</span>
                      </td>
                      <td>
                        <span class="font-weight-bolder d-block font-size-md">{{
                          application.submitted_date | formatDateTime
                        }}</span>
                      </td>
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          application.application_type.name
                        }}</span>
                        <span class="text-muted font-weight-bold">{{
                          application.subtitle
                        }}</span>
                      </td>
                      <td>
                        <span
                          class="
                            label label-md label-inline
                            font-weight-bold
                            h-25
                          "
                          v-bind:class="`label-light-${application.status.color_class}`"
                          >{{ application.status.nameMS | upperCase }}</span
                        >
                      </td>
                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="font-weight-bolder d-block font-size-md"
                              v-bind="attrs"
                              v-on="on"
                              style="
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                max-width: 400px;
                              "
                            >
                              {{ application.remark }}
                            </div>
                          </template>
                          <span>{{ application.remark }}</span>
                        </v-tooltip>
                      </td>
                      <td>
                        <span class="font-weight-bolder d-block font-size-md">
                          {{
                            application.approved_application.length > 0
                              ? application.approved_application[0].user.full_name
                              : ""
                          }}
                        </span>
                      </td>
                      <td>
                        <span class="font-weight-bolder d-block font-size-md">
                          {{
                            application.approved_application.length > 1
                              ? application.approved_application[1].user.full_name
                              : ""
                          }}
                        </span>
                      </td>

                      <td class="pr-0 text-right">
                        <v-btn
                          icon
                          class="
                            btn btn-icon btn-light btn-hover-primary btn-sm
                            mx-3
                          "
                          @click="next(application)"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                            <inline-svg
                              src="/media/svg/icons/Communication/Write.svg"
                            ></inline-svg>
                            <!--end::Svg Icon-->
                          </span>
                        </v-btn>
                        <!-- <v-btn
                            icon
                            class="
                              btn btn-icon btn-light btn-hover-primary btn-sm"
                            @click="openLogs(application)"
                          >
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                              <inline-svg
                                src="media/svg/icons/General/Visible.svg"
                              ></inline-svg>
                            </span>
                          </v-btn> -->
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <td colspan="10" class="text-center">
                      <p>Tiada rekod</p>
                    </td>
                  </template>
                </tbody>
              </table>
            </div>

            <nav>
              <ul class="pagination justify-content-end">
                <div v-for="link in KIVApplications.links" :key="link.label">
                  <li
                    v-bind:class="`page-item ${
                      link.url == null ? 'disabled' : ''
                    } ${link.active == true ? 'active' : ''}`"
                  >
                    <a
                      class="page-link"
                      @click="paginate(link.url)"
                      :aria-label="link.label"
                      >{{ decodeHtml(link.label) }}</a
                    >
                  </li>
                </div>
              </ul>
            </nav>
          </div>
        </div>
      </b-tab>
      <b-tab title="Ditolak" lazy>
        <div class="card card-custom gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder"
                >Senarai Ditolak: Batch {{ this.$route.params.batch_no }}
              </span>
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-0">
            <!--begin::Table-->
            <div class="table-responsive table-hover">
              <table class="table table-head-custom table-vertical-center">
                <thead>
                  <tr class="text-center">
                    <th class="pl-0">No.</th>
                    <th class="pl-0">Cawangan</th>
                    <th class="pl-0">No. Rujukan</th>
                    <th>
                      Tarikh Permohonan
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Down-2.svg-->
                    </th>
                    <th class="pl-0">Jenis Permohonan</th>
                    <th>Status</th>
                    <th>Nota</th>
                    <th>Kelulusan 1</th>
                    <th>Kelulusan 2</th>
                    <th class="pr-0 text-right" style="min-width: 100px">
                      Tindakan
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="rejectedApplications.data.length > 0">
                    <tr
                      class="text-center"
                      v-for="(application, i) in rejectedApplications.data"
                      :key="application.id"
                    >
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          i + rejectedApplications.from
                        }}</span>
                      </td>
                      <td>
                        <span
                          class="
                            label label-md label-inline
                            font-weight-bolder
                            h-25
                            label-light-primary
                          "
                        >
                          {{ application.branch.name.toUpperCase() }}
                        </span>
                      </td>
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          application.ref_no
                        }}</span>
                      </td>
                      <td>
                        <span class="font-weight-bolder d-block font-size-md">{{
                          application.submitted_date | formatDateTime
                        }}</span>
                      </td>
                      <td class="pl-0">
                        <span class="font-weight-bolder d-block font-size-md">{{
                          application.application_type.name
                        }}</span>
                        <span class="text-muted font-weight-bold">{{
                          application.subtitle
                        }}</span>
                      </td>
                      <td>
                        <span
                          class="
                            label label-md label-inline
                            font-weight-bold
                            h-25
                          "
                          v-bind:class="`label-light-${application.status.color_class}`"
                          >{{ application.status.nameMS | upperCase }}</span
                        >
                      </td>
                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="font-weight-bolder d-block font-size-md"
                              v-bind="attrs"
                              v-on="on"
                              style="
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                max-width: 400px;
                              "
                            >
                              {{ application.remark }}
                            </div>
                          </template>
                          <span>{{ application.remark }}</span>
                        </v-tooltip>
                      </td>
                      <td>
                        <span class="font-weight-bolder d-block font-size-md">
                          {{
                            application.approved_application.length > 0
                              ? application.approved_application[0].user.full_name
                              : ""
                          }}
                        </span>
                      </td>
                      <td>
                        <span class="font-weight-bolder d-block font-size-md">
                          {{
                            application.approved_application.length > 1
                              ? application.approved_application[1].user.full_name
                              : ""
                          }}
                        </span>
                      </td>

                      <td class="pr-0 text-right">
                        <v-btn
                          icon
                          class="
                            btn btn-icon btn-light btn-hover-primary btn-sm
                            mx-3
                          "
                          @click="next(application)"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                            <inline-svg
                              src="/media/svg/icons/Communication/Write.svg"
                            ></inline-svg>
                            <!--end::Svg Icon-->
                          </span>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <td colspan="10" class="text-center">
                      <p>Tiada rekod</p>
                    </td>
                  </template>
                </tbody>
              </table>
            </div>

            <nav>
              <ul class="pagination justify-content-start">
                <div v-for="link in rejectedApplications.links" :key="link.label">
                  <li class="pr-1"
                    v-bind:class="`page-item ${
                      link.url == null ? 'disabled' : ''
                    } ${link.active == true ? 'active' : ''}`"
                  >
                    <a
                      class="page-link"
                      @click="paginate(link.url)"
                      :aria-label="link.label"
                      >{{ decodeHtml(link.label) }}</a
                    >
                  </li>
                </div>
              </ul>
            </nav>
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <ErrorMsgDialog
      v-model="msgERR.show"
      :modal="msgERR.modal"
      :title="msgERR.title"
      :content="msgERR.message"
      :showProceedButton="true"
      :showBackButton="false"
      :delayOnProceed="false"
      :isTypeError="true"
      proceedButton="OK"
    ></ErrorMsgDialog>

    <InfoMsgDialog
      v-model="msgINFO.show"
      :modal="msgINFO.modal"
      :title="msgINFO.title"
      :content="msgINFO.message"
      :showProceedButton="true"
      :showBackButton="false"
      :delayOnProceed="false"
      proceedButton="OK"
      @onProceed="infoProceedOK()"
    ></InfoMsgDialog>

  </div>
</template>

<script>
import {
  mapGetters,
} from "vuex";

import {
  errorMessages,
  extractPagingInfoOnly,
} from "@/core/services/helper.service";

import DataList from "@/view/pages/widgets/DataList.vue";
// import StatusSummary from "@/view/pages/widgets/StatusSummary.vue";
// import InfoMsgDialog from "@/view/pages/widgets/MessageDialog.vue";
// import ErrorMsgDialog from "@/view/pages/widgets/MessageDialog.vue";

export default {
  name: "ApproverAppList",
  components: {
    DataList,
    // StatusSummary,
    // InfoMsgDialog,
    // ErrorMsgDialog,
  },

  props: {
    pendingApplications: {},
    apprApplications: {},
    KIVApplications: {},
    rejectedApplications: {},
    prevPath: String,
  },
  data() {
    return {
      batch: "",
      overlay: true,

      msgERR: {
        show: false,
        modal: false,
        title: "Ralat",
        message: "",
        nric: "",
        run: 0,
      },

      msgINFO: {
        show: false,
        modal: false,
        title: "Info",
        message: "",
        nric: "",
        run: 0,
      },

      statuses: [
        {
          id: 1,
          title: "Permohonan",
          value: "20",
          class: "blue",
        },
        {
          id: 2,
          title: "Lulus",
          value: 2,
          class: "green",
        }
      ],

      headers: [
        {
          text: "No. Rujukan",
          value: "ref_no",
          type: String,
        },
        {
          text: "Tarikh Mohon",
          value: "submitted_date",
          type: Date,
          filter: "formatDateTime",
        },
        {
          text: "Status",
          value: "status.nameMS",
          color: "status.color_class",
          type: String,
          filter: "upperCase",
          class_template: {
            "h-25": true,
            "label": true,
            "label-md": true,
            "label-inline": true,
            "font-weight-bold": true,
          },
        },
        {
          text: "Tarikh Kelulusan",
          value: "approval_date",
          type: Date,
          filter: "formatDate",
        },
        {
          text: "Tarikh Pembayaran",
          value: "disb_date",
          type: Date,
          filter: "formatDate",
        },
        {
          text: "Jumlah Pembiayaan Diluluskan",
          value: "approved_amount",
          type: Number,
          filter: "fAmount",
          style: "white-space: nowrap;",
          class_template: {
            "text-primary": true,
          },
        },
        {
          text: "Surat Tawaran",
          value: "media_name",
          type: String,
        },
        {
          text: "Nota",
          value: "remark",
          type: String,
          tooltip: {
            style: "white-space: nowrap; text-overflow: ellipsis; overflow: hidden; max-width: 270px;",
            class: "text-dark-75",
          }
        },
      ],

    };
  },
  mounted() {
    // xxconsole.log(this.KIVApplications);
    // this.batch = this.$route.params.batch_no;
    this.closeOverlay();
  },
  methods: {
    decodeHtml: function (html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    setCheck(check) {
      if (check) {
        this.checked = check;
      } else {
        this.checked = false;
      }
    },
    paginate(url) {
      this.$emit("paginate", url);
    },
    next(form) {
      // debugger;
      this.$router.push({
        name: "ApplicationDetail",
        params: { id: form.id, url: form.approval_documents },
        query: {
          prevPath: this.prevPath,
        },
      });
    },
    openLogs(application) {
      this.$emit("showAppLogsDialog", application);
    },

    deleteApplication(form) {
      this.$emit("showAlertDeleteApplicationDialog", form);
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 2000);
    },
  },
};
</script>

<template>
  <div>
    <AppLogsDialog v-model="openAppLogsDialog" :logs="logs"></AppLogsDialog>
    <PendingApplicationList
      :pendingApplications="pendingApplications"
      :apprApplications="apprApplications"
      :KIVApplications="kivApplications"
      :rejectedApplications="rejectedApplications"
      :prevPath="this.$route.fullPath"
      @showAppLogsDialog="showAppLogsDialog"
    >
    </PendingApplicationList>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  SET_BREADCRUMB,
  ADD_BREADCRUMB,
  POP_BREADCRUMB,
} from "@/core/services/store/breadcrumbs.module";
import {
  GET_PENDING_APPLICATIONS_BY_BATCH,
  GET_APPROVED_APPLICATION_BY_BATCH,
  GET_KIV_APPLICATION_BY_BATCH,
  GET_REJECTED_APPLICATION_BY_BATCH,
  SET_PENDING_APPLICATIONS,
  SET_APPROVED_APPLICATIONS,
  SET_KIV_APPLICATIONS,
  SET_REJECTED_APPLICATIONS,
} from "@/core/services/store/approvedapplication.module";
import {
  GET_APP_LOGS,
  SET_APP_LOGS,
} from "@/core/services/store/applogs.module";

import AppLogsDialog from "@/view/pages/widgets/AppLogsDialog.vue";
import PendingApplicationList from "@/view/pages/widgets/approver/DataList.vue";
export default {
  name: "ApplicationByBatch",
  components: {
    AppLogsDialog,
    PendingApplicationList,
  },
  data() {
    return {
      application: {},
      batch: null,
      openAppLogsDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      "pendingApplications",
      "apprApplications",
      "kivApplications",
      "rejectedApplications",
      "logs",
    ]),
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Senarai Batch", route: "/dashboard" },
      // { title: "Senarai Permohonan" },
      {
        title: "Senarai Batch " + this.$route.params.batch_no,
        route: this.$route.fullPath,
      },
    ]);
    // this.$store.dispatch(ADD_BREADCRUMB, [
    //   // { title: "Senarai Batch", route: "/dashboard" },
    //   {
    //     title: "Senarai Batch " + this.$route.params.batch_no,
    //     route: this.$route.fullPath,
    //   },
    // ]);

    this.batch = this.$route.params.batch;
    this.$store
      .dispatch(GET_PENDING_APPLICATIONS_BY_BATCH, {
        detail: this.$route.params.detail,
        batch_no: this.$route.params.batch_no,
      })
      .then((response) => {
        this.$store.commit(SET_PENDING_APPLICATIONS, response.data.data);
      });

    await this.$store
      .dispatch(GET_APPROVED_APPLICATION_BY_BATCH, {
        detail: this.$route.params.detail,
        batch_no: this.$route.params.batch_no,
        approved: true,
      })
      .then((response) => {
        // xxconsole.log(response);
        this.$store.commit(SET_APPROVED_APPLICATIONS, response.data.data);
      });

    await this.$store
      .dispatch(GET_KIV_APPLICATION_BY_BATCH, {
        detail: this.$route.params.detail,
        batch_no: this.$route.params.batch_no,
        // approved: true,
        kiv: true,
      })
      .then((response) => {
        this.$store.commit(SET_KIV_APPLICATIONS, response.data.data);
      });

    await this.$store
      .dispatch(GET_REJECTED_APPLICATION_BY_BATCH, {
        detail: true,
        batch_no: this.$route.params.batch_no,
        // approved: true,
        // kiv: false,
        rejected: true,
      })
      .then((response) => {
        if (response.data.success == true) {
          // xxconsole.log(response);
          this.$store.commit(SET_REJECTED_APPLICATIONS, response.data.data);
        }
      });
  },
  beforeDestroy() {
    // xxconsole.log("Before Destroy: ApplicationByBatch")
    // this.$store.dispatch(POP_BREADCRUMB);
  },
  methods: {
    async showAppLogsDialog(application) {
      // xxconsole.log(application);
      this.application = application;
      if (this.application) {
        await this.$store
          .dispatch(GET_APP_LOGS, {
            application_id: this.application.id,
          })
          .then((response) => {
            if (response.data.success == true) {
              this.$store.commit(SET_APP_LOGS, response.data.data);
              this.openAppLogsDialog = true;
            }
          });
      }
    },
  },
};
</script>
<style scoped>
</style>
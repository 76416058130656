var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AppLogsDialog", {
        attrs: { logs: _vm.logs },
        model: {
          value: _vm.openAppLogsDialog,
          callback: function ($$v) {
            _vm.openAppLogsDialog = $$v
          },
          expression: "openAppLogsDialog",
        },
      }),
      _c("PendingApplicationList", {
        attrs: {
          pendingApplications: _vm.pendingApplications,
          apprApplications: _vm.apprApplications,
          KIVApplications: _vm.kivApplications,
          rejectedApplications: _vm.rejectedApplications,
          prevPath: this.$route.fullPath,
        },
        on: { showAppLogsDialog: _vm.showAppLogsDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        { attrs: { "active-nav-item-class": "font-weight-bold" } },
        [
          _c(
            "b-tab",
            { attrs: { title: "Belum lulus", lazy: "" } },
            [
              _c("DataList", {
                attrs: {
                  title:
                    "Senarai Permohonan: Batch " + this.$route.params.batch_no,
                  appsData: _vm.pendingApplications,
                  appsConfig: _vm.headers,
                  detailButton: true,
                  deleteButton: true,
                },
                on: {
                  onDetail: function ($event) {
                    return _vm.goToEditPage($event)
                  },
                  onDelete: function ($event) {
                    return _vm.goToDeleteApp($event)
                  },
                },
              }),
              _c("div", { staticClass: "card card-custom gutter-b" }, [
                _c("div", { staticClass: "card-header border-0 py-5" }, [
                  _c(
                    "h3",
                    { staticClass: "card-title align-items-start flex-column" },
                    [
                      _c(
                        "span",
                        { staticClass: "card-label font-weight-bolder" },
                        [
                          _vm._v(
                            "Senarai Permohonan: Batch " +
                              _vm._s(this.$route.params.batch_no) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "card-body py-0" }, [
                  _c("div", { staticClass: "table-responsive table-hover" }, [
                    _c(
                      "table",
                      {
                        staticClass:
                          "table table-head-custom table-vertical-center",
                      },
                      [
                        _c("thead", [
                          _c("tr", { staticClass: "text-center" }, [
                            _c("th", { staticClass: "pl-0" }, [_vm._v("No.")]),
                            _c("th", { staticClass: "pl-0" }, [
                              _vm._v("Cawangan"),
                            ]),
                            _c("th", { staticClass: "pl-0" }, [
                              _vm._v("No. Rujukan"),
                            ]),
                            _c("th", [_vm._v("Tarikh Permohonan")]),
                            _c("th", { staticClass: "pl-0" }, [
                              _vm._v("Jenis Permohonan"),
                            ]),
                            _c("th", [_vm._v("Status")]),
                            _c("th", [_vm._v("Nota")]),
                            _c("th", [_vm._v("Kelulusan 1")]),
                            _c("th", [_vm._v("Kelulusan 2")]),
                            _c(
                              "th",
                              {
                                staticClass: "pr-0 text-right",
                                staticStyle: { "min-width": "100px" },
                              },
                              [_vm._v("Tindakan")]
                            ),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm.pendingApplications.data.length > 0
                              ? _vm._l(
                                  _vm.pendingApplications.data,
                                  function (application, i) {
                                    return _c(
                                      "tr",
                                      {
                                        key: application.id,
                                        staticClass: "text-center",
                                      },
                                      [
                                        _c("td", { staticClass: "pl-0" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bolder d-block font-size-md",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  i +
                                                    _vm.pendingApplications.from
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("td", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                          label label-md label-inline\n                          font-weight-bolder\n                          h-25\n                          label-light-primary\n                        ",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    application.branch.name.toUpperCase()
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("td", { staticClass: "pl-0" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bolder d-block font-size-md",
                                            },
                                            [_vm._v(_vm._s(application.ref_no))]
                                          ),
                                        ]),
                                        _c("td", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bolder d-block font-size-md",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatDateTime")(
                                                    application.submitted_date
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("td", { staticClass: "pl-0" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bolder d-block font-size-md",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  application.application_type
                                                    .name
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-muted font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(application.subtitle)
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("td", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                          label label-md label-inline\n                          font-weight-bold\n                          h-25\n                        ",
                                              class:
                                                "label-light-" +
                                                application.status.color_class,
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("upperCase")(
                                                    application.status.nameMS
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function (ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "div",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bolder d-block font-size-md",
                                                                  staticStyle: {
                                                                    "white-space":
                                                                      "nowrap",
                                                                    "text-overflow":
                                                                      "ellipsis",
                                                                    overflow:
                                                                      "hidden",
                                                                    "max-width":
                                                                      "400px",
                                                                  },
                                                                },
                                                                "div",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    application.remark
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(application.remark)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                application.approved_application
                                                  .length > 0
                                                  ? application
                                                      .approved_application[0]
                                                      .user.full_name
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                application.approved_application
                                                  .length > 1
                                                  ? application
                                                      .approved_application[1]
                                                      .user.full_name
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "pr-0 text-right" },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "\n                          btn btn-icon btn-light btn-hover-primary btn-sm\n                          mx-3\n                        ",
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.next(application)
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "svg-icon svg-icon-md svg-icon-primary",
                                                  },
                                                  [
                                                    _c("inline-svg", {
                                                      attrs: {
                                                        src: "/media/svg/icons/Communication/Write.svg",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                )
                              : [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-center",
                                      attrs: { colspan: "10" },
                                    },
                                    [_c("p", [_vm._v("Tiada rekod")])]
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                  _c("nav", [
                    _c(
                      "ul",
                      { staticClass: "pagination justify-content-end" },
                      _vm._l(_vm.pendingApplications.links, function (link) {
                        return _c("div", { key: link.label }, [
                          _c(
                            "li",
                            {
                              class:
                                "page-item " +
                                (link.url == null ? "disabled" : "") +
                                " " +
                                (link.active == true ? "active" : ""),
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "page-link",
                                  attrs: { "aria-label": link.label },
                                  on: {
                                    click: function ($event) {
                                      return _vm.paginate(link.url)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.decodeHtml(link.label)))]
                              ),
                            ]
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c("b-tab", { attrs: { title: "Lulus", lazy: "" } }, [
            _c("div", { staticClass: "card card-custom gutter-b" }, [
              _c("div", { staticClass: "card-header border-0 py-5" }, [
                _c(
                  "h3",
                  { staticClass: "card-title align-items-start flex-column" },
                  [
                    _c(
                      "span",
                      { staticClass: "card-label font-weight-bolder" },
                      [
                        _vm._v(
                          "Senarai Lulus: Batch " +
                            _vm._s(this.$route.params.batch_no) +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "card-body py-0" }, [
                _c("div", { staticClass: "table-responsive table-hover" }, [
                  _c(
                    "table",
                    {
                      staticClass:
                        "table table-head-custom table-vertical-center",
                    },
                    [
                      _c("thead", [
                        _c("tr", { staticClass: "text-center" }, [
                          _c("th", { staticClass: "pl-0" }, [_vm._v("No.")]),
                          _c("th", { staticClass: "pl-0" }, [
                            _vm._v("Cawangan"),
                          ]),
                          _c("th", { staticClass: "pl-0" }, [
                            _vm._v("No. Rujukan"),
                          ]),
                          _c("th", [_vm._v(" Tarikh Permohonan ")]),
                          _c("th", { staticClass: "pl-0" }, [
                            _vm._v("Jenis Permohonan"),
                          ]),
                          _c("th", [_vm._v("Status")]),
                          _c("th", [_vm._v("Nota")]),
                          _c("th", [_vm._v("Kelulusan 1")]),
                          _c("th", [_vm._v("Kelulusan 2")]),
                          _c(
                            "th",
                            {
                              staticClass: "pr-0 text-right",
                              staticStyle: { "min-width": "100px" },
                            },
                            [_vm._v(" Tindakan ")]
                          ),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm.apprApplications.data.length > 0
                            ? _vm._l(
                                _vm.apprApplications.data,
                                function (application, i) {
                                  return _c(
                                    "tr",
                                    {
                                      key: application.id,
                                      staticClass: "text-center",
                                    },
                                    [
                                      _c("td", { staticClass: "pl-0" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                i + _vm.apprApplications.from
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "\n                          label label-md label-inline\n                          font-weight-bolder\n                          h-25\n                          label-light-primary\n                        ",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  application.branch.name.toUpperCase()
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", { staticClass: "pl-0" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [_vm._v(_vm._s(application.ref_no))]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDateTime")(
                                                  application.submitted_date
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", { staticClass: "pl-0" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                application.application_type
                                                  .name
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-muted font-weight-bold",
                                          },
                                          [_vm._v(_vm._s(application.subtitle))]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "\n                          label label-md label-inline\n                          font-weight-bold\n                          h-25\n                        ",
                                            class:
                                              "label-light-" +
                                              application.status.color_class,
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("upperCase")(
                                                  application.status.nameMS
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "div",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "font-weight-bolder d-block font-size-md",
                                                                staticStyle: {
                                                                  "white-space":
                                                                    "nowrap",
                                                                  "text-overflow":
                                                                    "ellipsis",
                                                                  overflow:
                                                                    "hidden",
                                                                  "max-width":
                                                                    "400px",
                                                                },
                                                              },
                                                              "div",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  application.remark
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(application.remark)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  application
                                                    .approved_application
                                                    .length > 0
                                                    ? application
                                                        .approved_application[0]
                                                        .user.full_name
                                                    : ""
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  application
                                                    .approved_application
                                                    .length > 1
                                                    ? application
                                                        .approved_application[1]
                                                        .user.full_name
                                                    : ""
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "pr-0 text-right" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "\n                          btn btn-icon btn-light btn-hover-primary btn-sm\n                          mx-3\n                        ",
                                              attrs: { icon: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.next(application)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "svg-icon svg-icon-md svg-icon-primary",
                                                },
                                                [
                                                  _c("inline-svg", {
                                                    attrs: {
                                                      src: "/media/svg/icons/Communication/Write.svg",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }
                              )
                            : [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "10" },
                                  },
                                  [_c("p", [_vm._v("Tiada rekod")])]
                                ),
                              ],
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
                _c("nav", [
                  _c(
                    "ul",
                    { staticClass: "pagination justify-content-end" },
                    _vm._l(_vm.pendingApplications.links, function (link) {
                      return _c("div", { key: link.label }, [
                        _c(
                          "li",
                          {
                            class:
                              "page-item " +
                              (link.url == null ? "disabled" : "") +
                              " " +
                              (link.active == true ? "active" : ""),
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "page-link",
                                attrs: { "aria-label": link.label },
                                on: {
                                  click: function ($event) {
                                    return _vm.paginate(link.url)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.decodeHtml(link.label)))]
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("b-tab", { attrs: { title: "KIV" } }, [
            _c("div", { staticClass: "card card-custom gutter-b" }, [
              _c("div", { staticClass: "card-header border-0 py-5" }, [
                _c(
                  "h3",
                  { staticClass: "card-title align-items-start flex-column" },
                  [
                    _c(
                      "span",
                      { staticClass: "card-label font-weight-bolder" },
                      [
                        _vm._v(
                          "Senarai KIV: Batch " +
                            _vm._s(this.$route.params.batch_no) +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "card-body py-0" }, [
                _c("div", { staticClass: "table-responsive table-hover" }, [
                  _c(
                    "table",
                    {
                      staticClass:
                        "table table-head-custom table-vertical-center",
                    },
                    [
                      _c("thead", [
                        _c("tr", { staticClass: "text-center" }, [
                          _c("th", { staticClass: "pl-0" }, [_vm._v("No.")]),
                          _c("th", { staticClass: "pl-0" }, [
                            _vm._v("Cawangan"),
                          ]),
                          _c("th", { staticClass: "pl-0" }, [
                            _vm._v("No. Rujukan"),
                          ]),
                          _c("th", [_vm._v(" Tarikh Permohonan ")]),
                          _c("th", { staticClass: "pl-0" }, [
                            _vm._v("Jenis Permohonan"),
                          ]),
                          _c("th", [_vm._v("Status")]),
                          _c("th", [_vm._v("Nota")]),
                          _c("th", [_vm._v("Kelulusan 1")]),
                          _c("th", [_vm._v("Kelulusan 2")]),
                          _c(
                            "th",
                            {
                              staticClass: "pr-0 text-right",
                              staticStyle: { "min-width": "100px" },
                            },
                            [_vm._v(" Tindakan ")]
                          ),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm.KIVApplications.data.length > 0
                            ? _vm._l(
                                _vm.KIVApplications.data,
                                function (application, i) {
                                  return _c(
                                    "tr",
                                    {
                                      key: application.id,
                                      staticClass: "text-center",
                                    },
                                    [
                                      _c("td", { staticClass: "pl-0" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                i + _vm.KIVApplications.from
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "\n                          label label-md label-inline\n                          font-weight-bolder\n                          h-25\n                          label-light-primary\n                        ",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  application.branch.name.toUpperCase()
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", { staticClass: "pl-0" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [_vm._v(_vm._s(application.ref_no))]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDateTime")(
                                                  application.submitted_date
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", { staticClass: "pl-0" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                application.application_type
                                                  .name
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-muted font-weight-bold",
                                          },
                                          [_vm._v(_vm._s(application.subtitle))]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "\n                          label label-md label-inline\n                          font-weight-bold\n                          h-25\n                        ",
                                            class:
                                              "label-light-" +
                                              application.status.color_class,
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("upperCase")(
                                                  application.status.nameMS
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "div",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "font-weight-bolder d-block font-size-md",
                                                                staticStyle: {
                                                                  "white-space":
                                                                    "nowrap",
                                                                  "text-overflow":
                                                                    "ellipsis",
                                                                  overflow:
                                                                    "hidden",
                                                                  "max-width":
                                                                    "400px",
                                                                },
                                                              },
                                                              "div",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  application.remark
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(application.remark)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  application
                                                    .approved_application
                                                    .length > 0
                                                    ? application
                                                        .approved_application[0]
                                                        .user.full_name
                                                    : ""
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  application
                                                    .approved_application
                                                    .length > 1
                                                    ? application
                                                        .approved_application[1]
                                                        .user.full_name
                                                    : ""
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "pr-0 text-right" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "\n                          btn btn-icon btn-light btn-hover-primary btn-sm\n                          mx-3\n                        ",
                                              attrs: { icon: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.next(application)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "svg-icon svg-icon-md svg-icon-primary",
                                                },
                                                [
                                                  _c("inline-svg", {
                                                    attrs: {
                                                      src: "/media/svg/icons/Communication/Write.svg",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }
                              )
                            : [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "10" },
                                  },
                                  [_c("p", [_vm._v("Tiada rekod")])]
                                ),
                              ],
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
                _c("nav", [
                  _c(
                    "ul",
                    { staticClass: "pagination justify-content-end" },
                    _vm._l(_vm.KIVApplications.links, function (link) {
                      return _c("div", { key: link.label }, [
                        _c(
                          "li",
                          {
                            class:
                              "page-item " +
                              (link.url == null ? "disabled" : "") +
                              " " +
                              (link.active == true ? "active" : ""),
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "page-link",
                                attrs: { "aria-label": link.label },
                                on: {
                                  click: function ($event) {
                                    return _vm.paginate(link.url)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.decodeHtml(link.label)))]
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("b-tab", { attrs: { title: "Ditolak", lazy: "" } }, [
            _c("div", { staticClass: "card card-custom gutter-b" }, [
              _c("div", { staticClass: "card-header border-0 py-5" }, [
                _c(
                  "h3",
                  { staticClass: "card-title align-items-start flex-column" },
                  [
                    _c(
                      "span",
                      { staticClass: "card-label font-weight-bolder" },
                      [
                        _vm._v(
                          "Senarai Ditolak: Batch " +
                            _vm._s(this.$route.params.batch_no) +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "card-body py-0" }, [
                _c("div", { staticClass: "table-responsive table-hover" }, [
                  _c(
                    "table",
                    {
                      staticClass:
                        "table table-head-custom table-vertical-center",
                    },
                    [
                      _c("thead", [
                        _c("tr", { staticClass: "text-center" }, [
                          _c("th", { staticClass: "pl-0" }, [_vm._v("No.")]),
                          _c("th", { staticClass: "pl-0" }, [
                            _vm._v("Cawangan"),
                          ]),
                          _c("th", { staticClass: "pl-0" }, [
                            _vm._v("No. Rujukan"),
                          ]),
                          _c("th", [_vm._v(" Tarikh Permohonan ")]),
                          _c("th", { staticClass: "pl-0" }, [
                            _vm._v("Jenis Permohonan"),
                          ]),
                          _c("th", [_vm._v("Status")]),
                          _c("th", [_vm._v("Nota")]),
                          _c("th", [_vm._v("Kelulusan 1")]),
                          _c("th", [_vm._v("Kelulusan 2")]),
                          _c(
                            "th",
                            {
                              staticClass: "pr-0 text-right",
                              staticStyle: { "min-width": "100px" },
                            },
                            [_vm._v(" Tindakan ")]
                          ),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm.rejectedApplications.data.length > 0
                            ? _vm._l(
                                _vm.rejectedApplications.data,
                                function (application, i) {
                                  return _c(
                                    "tr",
                                    {
                                      key: application.id,
                                      staticClass: "text-center",
                                    },
                                    [
                                      _c("td", { staticClass: "pl-0" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                i +
                                                  _vm.rejectedApplications.from
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "\n                          label label-md label-inline\n                          font-weight-bolder\n                          h-25\n                          label-light-primary\n                        ",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  application.branch.name.toUpperCase()
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", { staticClass: "pl-0" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [_vm._v(_vm._s(application.ref_no))]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDateTime")(
                                                  application.submitted_date
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", { staticClass: "pl-0" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                application.application_type
                                                  .name
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-muted font-weight-bold",
                                          },
                                          [_vm._v(_vm._s(application.subtitle))]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "\n                          label label-md label-inline\n                          font-weight-bold\n                          h-25\n                        ",
                                            class:
                                              "label-light-" +
                                              application.status.color_class,
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("upperCase")(
                                                  application.status.nameMS
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "div",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "font-weight-bolder d-block font-size-md",
                                                                staticStyle: {
                                                                  "white-space":
                                                                    "nowrap",
                                                                  "text-overflow":
                                                                    "ellipsis",
                                                                  overflow:
                                                                    "hidden",
                                                                  "max-width":
                                                                    "400px",
                                                                },
                                                              },
                                                              "div",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  application.remark
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(application.remark)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  application
                                                    .approved_application
                                                    .length > 0
                                                    ? application
                                                        .approved_application[0]
                                                        .user.full_name
                                                    : ""
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bolder d-block font-size-md",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  application
                                                    .approved_application
                                                    .length > 1
                                                    ? application
                                                        .approved_application[1]
                                                        .user.full_name
                                                    : ""
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "pr-0 text-right" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "\n                          btn btn-icon btn-light btn-hover-primary btn-sm\n                          mx-3\n                        ",
                                              attrs: { icon: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.next(application)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "svg-icon svg-icon-md svg-icon-primary",
                                                },
                                                [
                                                  _c("inline-svg", {
                                                    attrs: {
                                                      src: "/media/svg/icons/Communication/Write.svg",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }
                              )
                            : [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "10" },
                                  },
                                  [_c("p", [_vm._v("Tiada rekod")])]
                                ),
                              ],
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
                _c("nav", [
                  _c(
                    "ul",
                    { staticClass: "pagination justify-content-start" },
                    _vm._l(_vm.rejectedApplications.links, function (link) {
                      return _c("div", { key: link.label }, [
                        _c(
                          "li",
                          {
                            staticClass: "pr-1",
                            class:
                              "page-item " +
                              (link.url == null ? "disabled" : "") +
                              " " +
                              (link.active == true ? "active" : ""),
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "page-link",
                                attrs: { "aria-label": link.label },
                                on: {
                                  click: function ($event) {
                                    return _vm.paginate(link.url)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.decodeHtml(link.label)))]
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c("ErrorMsgDialog", {
        attrs: {
          modal: _vm.msgERR.modal,
          title: _vm.msgERR.title,
          content: _vm.msgERR.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          isTypeError: true,
          proceedButton: "OK",
        },
        model: {
          value: _vm.msgERR.show,
          callback: function ($$v) {
            _vm.$set(_vm.msgERR, "show", $$v)
          },
          expression: "msgERR.show",
        },
      }),
      _c("InfoMsgDialog", {
        attrs: {
          modal: _vm.msgINFO.modal,
          title: _vm.msgINFO.title,
          content: _vm.msgINFO.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          proceedButton: "OK",
        },
        on: {
          onProceed: function ($event) {
            return _vm.infoProceedOK()
          },
        },
        model: {
          value: _vm.msgINFO.show,
          callback: function ($$v) {
            _vm.$set(_vm.msgINFO, "show", $$v)
          },
          expression: "msgINFO.show",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }